// Here you can add other styles
body{
  color: black;
}
table{
  th, td{
    color: black !important;
  }
}
.relative{
  position: relative;
}

.pointer{
  cursor: pointer;
}

/* PAGINATION ==================== */

#pressRealesePagination .pagination a {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px !important;
  border: 1px solid #8898AA;
  color: #535861 !important;
  text-decoration: none;
  margin: 0px 2px;
  font-weight: 600;
}

#pressRealesePagination .pagination__link--active a {
  background-color: transparent !important;
  color: #3B3E98;
  border: 1px solid #3B3E98;
}

#pressRealesePagination .pagination__link--disabled a {
  color: #C8C9CC;
  border: 1px solid #83878D;
  background: #83878D;
}


/* END PAGINATION ========== */

/* VIDEO INPUT ========= */
.VideoInput_input {
  display: none;
	background-color: #000;
}

.VideoInput_video {
  display: block;
  margin: 0;
	background-color: #000;
}

.VideoInput_footer {
  width: 100%;
  min-height: 40px;
	padding: 15px;
  text-align: center;
}

.Videoinput_loader{
	position:absolute;
	top:0; 
	left:0; 
	right:0; 
	bottom:0; 
	background-color:#00000085;
	z-index: 999;
	justify-content: center;
	align-items: center;
	display: flex;

	.Videoinput_loader_icon{
		color: #fff;
	}
}
/* END VIDEO INPUT ========= */


/* CUSTOM DATE RANGE ========== */
.rdrDateRangePickerWrapper{
  display: flex !important;
}

.rdrCalendarWrapper{
  flex: 1 !important;
}

.rdrMonth{
  flex: 1 !important;
}

/* END CUSTOM DATE RANGE ========== */


/* GENERAL ============= */

.pagination {
  display: flex;
  padding: 0px 20px;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  /* padding: 5px 8px; */
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #8898AA;
  color: #8898AA !important;
  text-decoration: none;
  margin: 0px 2px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  background: #2d6ab6;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.rc-time-picker-input {
  border: 0px !important;
  padding: 0px !important;
  font-size: 1rem !important;
}